export default {
	state: {
		isDemo: false,
		folder: "",
		ui: 1,
		name: "Alfaser",
		fullname: "Alfaser S.A.",
		phone: "0351 487.2666",
		email: "atclientes@alfaser.com.ar",
		whatsapp: "5493516430813",
		fiscalkey: "30711253072",
		address: "Dr. Arturo Orgaz 1220 - Bº Villa Páez",
		city: "Córdoba",
		state: "Córdoba",
		zip_code: "5003",
		open_hours: [
			{day: 'Lunes',     open: [{from: '8:00', to: '13:30'}, {from: '14:00', to: '17:00'}] },
			{day: 'Martes',    open: [{from: '8:00', to: '13:30'}, {from: '14:00', to: '17:00'}] },
			{day: 'Miércoles', open: [{from: '8:00', to: '13:30'}, {from: '14:00', to: '17:00'}] },
			{day: 'Jueves',    open: [{from: '8:00', to: '13:30'}, {from: '14:00', to: '17:00'}] },
			{day: 'Viernes',   open: [{from: '8:00', to: '13:30'}, {from: '14:00', to: '17:00'}] },
			{day: 'Sábado',    open: [] },
			{day: 'Domingo',   open: [] },
		], 

		isGuestEnabled: true, // Habilito acceso a invitados (usuarios no registrados)
		enablePayments: true, // Habilita modulo de pagos para enviar el pedido.
		enableSurveys: false, // Habilita las encuestas en la app.
		orderMinimumAmount: 20000, // Mínimo para compras. TODO: esto debería obtenerse desde una API.
		freeDeliveryFrom: 80000,
		shipmentAmount: 0,
		showStockIconOnOrder: false,

		default_showTable: false, // modo de vista por defecto al iniciar por primera vez
		default_sendTo: 2,
		
		bankData: [{ name: "CÓRDOBA", branch: "000", cbu: "0000000000000000000000", accountNumber: "000-0000/00", alias: "" }],

		menuLinks: [
			// agregar un 0 en el array hideForRole para quitar la opción del menú a todos los roles
			{ text: "Home", icon: "mdi-home", route: "/", hideForRole: [] },
			{ text: "Productos", icon: "mdi-view-dashboard", route: "/productos", hideForRole: [] },
			{ text: "Aplicaciones", icon: "mdi-puzzle", route: "/aplicaciones", hideForRole: [0, 2, 9] },

			{ text: "Mi Carrito", icon: "mdi-cart", route: "/order-summary", hideForRole: [1, 2, 3] },
			{ text: "Mi Carrito", icon: "mdi-cart", route: "/carrito", hideForRole: [4, 5, 6, 7, 8, 9] },
			{ text: "Mis Pedidos", icon: "mdi-cart", route: "/pedidos", hideForRole: [9] },

			{ text: "Novedades", icon: "mdi-newspaper-variant", route: "/novedades", hideForRole: [0] },
			{ text: "Folletos Digitales", icon: "mdi-star", route: "/flyers", hideForRole: [0] },
			{ text: "Catálogos", icon: "mdi-book-open-variant", route: "/catalogos", hideForRole: [0, 2, 9] },
			{ text: "Descargas", icon: "mdi-download", route: "/descargas", hideForRole: [0, 2, 9] },
			{
				text: "Autogestión",
				icon: "mdi-file-document",
				hideForRole: [2, 9],
				children: [
					{ text: "Estado de Cuenta", icon: "mdi-file-check", route: "/comprobantes", hideForRole: [2, 9] },
					{ text: "Aviso de pago", icon: "mdi-message-star", route: "/aviso-pago", hideForRole: [2, 9] },
				],
			},
			{
				text: "Configuración",
				icon: "mdi-cog",
				hideForRole: [],
				children: [
					{ text: "Descuentos", icon: "mdi-ticket-percent", route: "/cfg-descuentos", hideForRole: [0] },
					{ text: "Utilidades", icon: "mdi-sack-percent", route: "/cfg-utilidades", hideForRole: [0] },
					{ text: "Mi cuenta", icon: "mdi-account", route: "/cfg-cuenta", hideForRole: [] },
				],
			},
			// { text: "Contacto",								icon: "mdi-email",								route: "/contacto", hideForRole: [1,2,9]},
		],

	},

	mutations: {
		// SHIPMENT_AMOUNT
		setShipmentAmount(state, newValue) {
			state.shipmentAmount = newValue;			
		},

		// FREE_SHIPPING_FROM
		setFreeShippingFrom(state, newValue) {
			state.freeDeliveryFrom = newValue;
		},

		// ORDER_MINIMUM_AMOUNT
		setOrderMinimumAmount(state, newValue) {
			state.orderMinimumAmount = newValue;
		}


	},
	
		
};