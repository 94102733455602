// import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/es5/locale/es';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

// OECHSLE

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#003DA6',									// #003DA6
				secondary: colors.blueGrey.base,		// #607D8B
				accent: colors.deepOrange.base,			// #FF5722
				featured: colors.blue.lighten5,			// #E3F2FD

				// error:		'#FF5252'
				// warning:	'#FFC107'
				// info:		'#2196F3'
				// success:	'#4CAF50'
			},
			// default: {
				// 	primary: '#003DA6',									// #003DA6 
				// 	secondary: colors.blueGrey,					// #607D8B
				// 	accent: colors.deepOrange.base,			// #FF5722
			// },
			
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'mdi'
	}
	
});
