import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import uuid from 'vue-uuid';

import "roboto-fontface/css/roboto/roboto-fontface.css"; // new
import "@mdi/font/css/materialdesignicons.css"; // new

import './registerServiceWorker';

Vue.use(VueAxios, axios, uuid)


	INFO:
/*
Public Key
TEST-ee4baf6d-41d0-4d46-87f1-6d3ebabfbc0a
Access Token
TEST-3646114560520415-013117-d174cfb32fb8079114ecf0363a1b7d03-102792970
*/

/*
// SDK de Mercado Pago
const mercadopago = require("mercadopago");
// Agregamos credenciales
mercadopago.configure({
	access_token: "TEST-3646114560520415-013117-d174cfb32fb8079114ecf0363a1b7d03-102792970",
});
*/

// axios.defaults.baseURL = store.getters.isDeveloping ? 'https://alfaser.com.ar/backend/api/' : '/backend/api/';
axios.defaults.baseURL = `${store.getters.isDeveloping ? "https://alfaser.com.ar" : ""}/backend/api/`;
// axios.defaults.baseURL = 'https://alftest.osapp.com.ar/backend/api/';
// axios.defaults.baseURL = 'https://alfaser.osapp.com.ar/backend/api/';
// axios.defaults.baseURL = 'https://alfaser.com.ar/backend/api/';

Vue.config.productionTip = false

new Vue({
	router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
